import { ICourses } from "../../Types/courses.interface";
import * as Yup from "yup";

const initialValues: ICourses = {
  name: "",
  endTime: "",
  averageRatings: "",
  bannerImage: null,
  duration: "",
  studentsEnrolled: "",
  plans: [
    {
      name: "",
      packageName: "",
      packageType: "",
      price: null,
      description: "",
      additionalInfo: "",
      batchName: "",
      combinationId: "",
      noOfTime: "",
      expiryDate: null,
    },
  ],
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Course Name is required"),
  endTime: Yup.string().required("End time of course is required"),
  averageRatings: Yup.string().required(
    "Average Ratings of course is required"
  ),
  bannerImage: Yup.mixed().required("Banner image is required"),
  duration: Yup.string().required("Duration of course is required"),
  studentsEnrolled: Yup.string().required(
    "Enrolled students of course is required"
  ),
  plans: Yup.array()
    .of(
      Yup.object().shape({
        packageName: Yup.string().required("Package name is required"),
        packageType: Yup.string().required("Package Type is required"),
        price: Yup.number().required("Package price is required"),
        batchName: Yup.string().required("Batch name is required"),
        combinationId: Yup.string().required("combinationId  is required"),
        noOfTime: Yup.string().required("Number of view time required"),
        expiryDate: Yup.number().required("Expiry Day  is required"),
      })
    )
    .min(1, "At least one page is required"),
});
export const course_form = {
  initialValues,
  validationSchema,
};
