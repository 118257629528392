import { useQuery } from "@tanstack/react-query";
import { request } from "../../../services/axios.service";
import apiurls from "../../apiurls";

const get = async (data: {
  page: number;
  limit: number;
  search: string;
  name: string;
  status: string;
}) => {
  const res: TServerResponse = await request({
    url: apiurls.GET_REGISTERED_STUDENTS,
    method: "GET",
    params: {
      page: data.page,
      limit: data.limit,
      search: data.search,
      courseName: data.name,
      all: "all",
      status: data.status,
    },
  });
  return res;
};

export const useGetRegisteredStudents = (data: {
  page: number;
  search: string;
  name: string;
  limit: number;
  status: string;
}) => {
  return useQuery({
    queryKey: [
      "get-registered-students",
      data.page,
      data.search,
      data.name,
      data.limit,
      data.status,
    ],
    queryFn: () => get(data),
  });
};
