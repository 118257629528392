import { TTableColumns } from "../../Types/table.interface";
import { IRegisterd } from "../../Types/registedStudents.interface";
import moment from "moment";
export const registeredStudentsColumn: TTableColumns<IRegisterd>[] = [
  {
    key: "sno",
    label: "S. No.",
    minWidth: 80,
  },
  {
    key: "name",
    label: "Name",
    renderCell: (value) => value.firstName + " " + value.lastName,
    minWidth: 250,
  },
  {
    key: "email",
    label: "Email",
    minWidth: 250,
  },
  {
    key: "mobile",
    label: "Mobile",
  },
  {
    key: "courseName",
    label: "Course",
    minWidth: 250,
  },
  {
    key: "packageName",
    label: "Package Name",
    minWidth: 200,
  },
  {
    key: "price",
    label: "Price",
    minWidth: 150,
  },
  {
    key: "purchasedDate",
    label: "Purchased At",
    renderCell: (value) =>
      moment(value.purchasedDate).format("DD-MMM-YYYY - hh:mm:ss A"),
    minWidth: 250,
  },
  {
    key: "pdfPath",
    label: "Invoice",
    renderCell: (value) => (
      <a
        href={value.pdfPath as string}
        target="_blank"
        rel="noopener noreferrer"
      >
        {value.pdfPath ? "Link" : "N/A"}
      </a>
    ),
  },
  {
    key: "expiryDate",
    label: "Expiry Date",
    renderCell: (value) =>
      value.status === "PAID"
        ? moment(value.expiryDate).format("DD-MMM-YYYY - hh:mm:ss A")
        : "N/A",
    minWidth: 250,
  },
  {
    key: "loginCredentials",
    label: "Login Credentials",
    renderCell: (value) => value?.softwareDetails?.loginCredentials ?? "N/A",
    minWidth: 180,
  },
  {
    key: "Password",
    label: "Password",
    renderCell: (value) => value?.softwareDetails?.Password ?? "N/A",
    minWidth: 150,
  },
  {
    key: "SoftwareLink",
    label: "Software Link",
    renderCell: (value) => (
      <a
        href={value?.softwareDetails?.SoftwareLink as string}
        target="_blank"
        rel="noopener noreferrer"
      >
        {value?.softwareDetails?.SoftwareLink ? "Link" : "N/A"}
      </a>
    ),
    minWidth: 100,
  },
];
